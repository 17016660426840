import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';


function MainCard(
  {
    border = true,
    boxShadow,
    children,
    content = true,
    contentSX = {},
    darkTitle,
    elevation,
    secondary,
    shadow,
    sx = {},
    title,
    backgroundColor,
    ...others
  },
  ref
) {
  const theme = useTheme();
  boxShadow = boxShadow;
  return (
    <Card
      elevation={elevation || 0}
      ref={ref}
      {...others}
      sx={{
        backgroundColor: backgroundColor || theme.palette.common.white,
        border: 'none',
        borderRadius: 2,
        borderColor: theme.palette.divider,
        boxShadow:  shadow,
        ':hover': {
          boxShadow: shadow
        },
        '& pre': {
          m: 0,
          p: '16px !important',
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.75rem'
        },
        ...sx
      }}
    >
      {content && <CardContent sx={contentSX}>{children}</CardContent>}
    </Card>
  );
}

export default forwardRef(MainCard);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.any,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modal: PropTypes.bool,
  others: PropTypes.any
};