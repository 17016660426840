import { Box, Toolbar } from "@mui/material"
import { Outlet } from "react-router-dom"
import { Footer } from "../Footer"
import Header from "../Header/Header"
import { Sidebar } from "../Sidebar"

export const Layout = () => {
    const drawerWidth = 240;
    return (
        <Box sx={{ display: 'flex' }}>
            <Header drawerWidth={drawerWidth} />
            <Sidebar drawerWidth={drawerWidth} />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}