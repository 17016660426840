import { Grid } from '@mui/material';
import StyledDataGrid from '../../Components/StyledGrid';
import { ApiService } from '../../utils/ApiService';
import { useEffect, useMemo, useState } from 'react';
import { CustomLoadingOverlay } from '../../Components/CustomOverlay';
import { PageHeading } from '../../Components/Common/PageHeading';
import { Box, TextField } from '@mui/material';

export const UsageStats = () => {

    const [usageStats, setUsageStats] = useState({ data: [], isLoading: true, error: null });
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        { field: 'col1', headerName: 'Username', flex: 0.6 },
        { field: 'col2', headerName: 'Package name', flex: 1 },
        { field: 'col3', headerName: 'Count', flex: 0.5 }
    ];

    useEffect(() => {
        setUsageStats({ ...usageStats, isLoading: true })
        ApiService('usage/stats', 'GET').
            then(response => setUsageStats({ ...usageStats, data: response.result, isLoading: false }))
            .catch(error => setUsageStats({ ...usageStats, error, isLoading: false }));
    }, []);

    const rows = useMemo(() => {
        if (!usageStats.data || usageStats.data.length === 0) return [];

        return usageStats.data
            .filter(stats => {
                const searchValue = searchTerm.toLowerCase();
                return (
                    stats.user_name.toLowerCase().includes(searchValue) ||
                    stats.pack_name.toLowerCase().includes(searchValue)
                );
            })
            .map((stats, i) => ({
                id: i + 1,
                col1: stats.user_name,
                col2: stats.pack_name,
                col3: stats.count,
            }));
    }, [usageStats, searchTerm]);

    return (
        <Grid sx={{ height: '45vw', width: '100%' }}>
            <Box display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
                <PageHeading variant="h3" textAlign="left" name="Usage Stats" />
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ width: "20rem", marginLeft: '20px' }}
                />
            </Box>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                rowHeight={40}
                autoHeight={true}
                disableRowSelectionOnClick={false}
                loading={usageStats.isLoading}
                slots={{
                    loadingOverlay: CustomLoadingOverlay
                }}
                localeText={{
                    noRowsLabel: "No Stats found"
                }}
            />
            <div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </Grid>
    );
}
