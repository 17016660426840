// material-ui
import Grid from '@mui/material/Grid';

// project import
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DatasetIcon from '@mui/icons-material/Dataset';
import DeselectIcon from '@mui/icons-material/Deselect';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Box, Skeleton, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useEffect, useMemo, useState } from 'react';
import { PageHeading } from '../../Components/Common/PageHeading.js';
import MainCard from '../../Components/Dashboard/MainCard.js';
import CombinedChartdata from '../../utils/CombinedChartdata.js'
import UtilsCard from '../../Components/Dashboard/UtilsCard.js';
import { ApiService } from '../../utils/ApiService.js';

const valueFormatter = (value) => `${value}`;

const StyledIcon = (props) => <props.Icon fontSize={'small'} sx={{ width: '35px', height: '35px', position: 'absolute', left: '33px', top: '12px', padding: '5px', backgroundColor: props.backgroundColor, borderRadius: '5px', color: '#fff' }} />

const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short' // Other option is 'long'
});

function StackedBarChart(props) {

    const { yLabel, dataKey, label, data } = props

    const chartSettings = useMemo(() => {
        const barColor = dataKey === "count" ? '#28357e' : '#02b2af';
        return {
            yAxis: [
                {
                    label: yLabel
                },
            ],
            series: [{ dataKey: dataKey, label, valueFormatter, color: barColor }],
            height: 450,
            sx: {
                [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                    transform: 'translateX(-10px)',
                },
            },

        };
    }, [])

    return (
        <BarChart
            dataset={data}
            {...chartSettings}
            leftAxis={{
                valueFormatter: (value) => {
                    return formatter.format(value);
                }
            }}
            xAxis={[
                {
                    scaleType: 'band',
                    dataKey: 'category',
                    valueFormatter: (category, context) => {
                        const splitCat = category.split(' ');
                        const firstPart = splitCat.splice(0, 1)
                        const pending = splitCat.join(' ')
                        return `${firstPart}\n${pending}`
                    }
                },
            ]}
            barLabel={(item, context) => {
                console.log(item, context, "Item and context.......")
                return null
            }}
        />
    );
}

export default function Dashboard() {
    const theme = useTheme();

    const [results, setResults] = useState({ libraries: [], userStats: [], combinedPlotData: {}, isLoading: true, error: null });


    useEffect(() => {
        setResults({ ...results, isLoading: true });
        const libraryAPICall = ApiService('libraries', 'GET');
        const userStatusApiCall = ApiService('usage/stats', 'GET');
        const combinedPlotDataApiCall = ApiService('combined_plot_data', 'GET');
        Promise.all([libraryAPICall, userStatusApiCall, combinedPlotDataApiCall])
            .then(response => {
                setResults({ ...results, libraries: response[0].result, userStats: response[1].result, combinedPlotData: response[2].result, isLoading: false })
            })
            .catch(error => setResults({ ...results, error, isLoading: false }));
    }, []);

    const sumKeyByCountSameCategories = (data, key) => {
        return Object.values(data?.reduce((acc, cur) => {
            acc[cur.category] = acc[cur.category]
                ? { ...cur, [key]: cur[key] + acc[cur.category][key] }
                : cur;
            return acc;
        }, {}));
    }

    const getUniqueLibraries = libraries => libraries?.filter((obj, index, arr) => arr.findIndex(y => y.category === obj.category) === index);

    const calculateCountForCards = (libraries, userStats) => {
        return getUniqueLibraries(libraries)?.map((x, i) => {
            let icon = null
            let backgroundColor = theme.palette.common.white
            let chipColor = theme.palette.primary.main
            switch (i) {
                case 0:
                    icon = AnalyticsIcon
                    backgroundColor = theme.palette.primary.main
                    chipColor = theme.palette.success.main
                    break;

                case 1:
                    icon = DatasetIcon
                    backgroundColor = theme.palette.success.main
                    chipColor = theme.palette.primary.main
                    break;

                case 2:
                    icon = GroupWorkIcon
                    backgroundColor = theme.palette.primary.main
                    chipColor = theme.palette.success.main
                    break;

                case 3:
                    icon = DeselectIcon
                    backgroundColor = theme.palette.success.main
                    chipColor = theme.palette.primary.main
                    break;

                default:
                    // Right now only having 4 categories.
                    if (i % 2 === 0) {
                        icon = GroupWorkIcon
                        backgroundColor = theme.palette.primary.main
                        chipColor = theme.palette.success.main
                    } else {
                        icon = DeselectIcon
                        backgroundColor = theme.palette.success.main
                        chipColor = theme.palette.primary.main
                    }
            }
            return {
                ...x,
                icon: <StyledIcon Icon={icon} backgroundColor={chipColor} />,
                backgroundColor,
                chipColor,
                usageCount: libraries.filter(l => l.category === x.category).length
            }
        });
    }

    const createUsersCountGraphData = (libraries, userStats) => {
        const userFilter = {};

        return sumKeyByCountSameCategories(
            libraries?.map(l => {
                let count = 0;

                userStats?.forEach(userStat => {
                    if (l?.packageName.toLowerCase() === userStat?.pack_name.split('.')[0].toLowerCase()) {
                        // Filter out duplicate users based on package name
                        if (userFilter[l?.packageName.toLowerCase()] === userStat?.user_name) {
                            return;
                        } else {
                            userFilter[l?.packageName.toLowerCase()] = userStat?.user_name; // Mark user as counted
                        }
                        count += 1;
                    }
                });

                return {
                    category: l?.category,
                    count: count
                };
            }),
            'count'
        );
    }

    const createUsageCountGraphData = (libraries, userStats) => {
        return sumKeyByCountSameCategories(
            libraries.map(l => ({
                category: l.category,
                usageCount: userStats
                    ?.filter(userStat =>
                        l?.packageName === userStat?.pack_name.split('.')[0] && userStat?.user_name !== 'root' // Exclude 'root'
                    )
                    .reduce((pv, cv) => pv + cv.count, 0)
            })),
            'usageCount'
        );
    }

    const createcloudUsageCountGraphData = (libraries, userStats) => {
        return sumKeyByCountSameCategories(
            libraries.map(l => ({
                category: l.category,
                usageCount: userStats
                    ?.filter(userStat =>
                        l?.packageName === userStat?.pack_name.split('.')[0] && userStat?.user_name === 'root' // Include 'root'
                    )
                    .reduce((pv, cv) => pv + cv.count, 0)
            })),
            'usageCount'
        );
    }

    const dashboardInfo = useMemo(() => {
        const { libraries, userStats, combinedPlotData } = results;
        return {
            cardsInfo: calculateCountForCards(libraries, userStats),
            usersGraphData: createUsersCountGraphData(libraries, userStats),
            usageCount: createUsageCountGraphData(libraries, userStats),
            cloudUsageCount: createcloudUsageCountGraphData(libraries, userStats),
            combinedPlotData: combinedPlotData
        }
    }, [results]);

    return (
        <>
            <Grid container rowSpacing={4.25} columnSpacing={2.75}>
                {/* row 1 */}
                <PageHeading variant="h3" textAlign="Left" name="Dashboard" />
                {!results.isLoading ? dashboardInfo.cardsInfo.map(lib =>
                    <Grid key={lib.id} item xs={12} sm={6} md={4} lg={3} sx={{ position: 'relative' }}>
                        <UtilsCard title={lib.category} packageName={lib.packageName} count={lib.usageCount} cardDescription={lib.tilesDescription} icon={lib.icon} cardColor={lib.backgroundColor} chipColor={lib.chipColor} theme={theme} />
                    </Grid>
                ) : <>
                    {Array(4).fill(0).map((d, i) => <Grid key={`lib.id-${d}-${i}`} item xs={12} sm={6} md={4} lg={3} sx={{ position: 'relative' }}><Skeleton elevation={5} sx={{ borderRadius: "5px" }} animation="wave" variant="rectangular" width={248} height={140} /></Grid>)}
                </>}
            </Grid>
            <Box sx={{ flexGrow: 1, mt: '25px', mb: '45px' }}>
                <Grid container spacing={2}>

                    <Grid item lg={6}>
                        <MainCard elevation={3}>
                            <StackedBarChart yLabel="Usage count" dataKey="usageCount" label="Usage Count (Dev)" data={dashboardInfo.usageCount} />
                        </MainCard>
                    </Grid>
                    <Grid item lg={6}>
                        <MainCard elevation={3}>
                            <StackedBarChart yLabel="Usage count" dataKey="usageCount" label="Usage Count (Cloud)" data={dashboardInfo.cloudUsageCount} />
                        </MainCard>
                    </Grid>
                    <Grid item lg={6}>
                        <MainCard elevation={3}>
                            <StackedBarChart yLabel="Number of users" dataKey="count" label="Users" data={dashboardInfo.usersGraphData} />
                        </MainCard>
                    </Grid>
                    <Grid item lg={6}>
                        <MainCard elevation={3}>
                            <CombinedChartdata data={dashboardInfo.combinedPlotData} ></CombinedChartdata>
                        </MainCard>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}