import React, { memo, useMemo } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

export const SideNavListItem = memo((props) => {

    const { to, selected, disablePadding, navMenu } = props;
    return (
        <ListItem
            disablePadding={disablePadding}
            component={Link}
            to={to}
        >
            <ListItemButton selected={selected}>
                <ListItemIcon>
                    {navMenu.icon}
                </ListItemIcon>
                <ListItemText primary={navMenu.name} />
            </ListItemButton>
        </ListItem>
    )
});