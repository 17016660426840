import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import { PageHeading } from '../../Components/Common/PageHeading';
import Readme from './Readme';
import { Box, TextField } from '@mui/material';
import { ApiService } from '../../utils/ApiService';
import StyledDataGrid from '../../Components/StyledGrid';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
// Styled components similar to the table example

export const Libraries = () => {
  const [libraryDetails, setLibraryDetails] = useState({ open: false, name: null });
  const [videourl, setVideourl] = useState("")
  const [searchTerm, setSearchTerm] = useState('');
  const [videomodalopen, setVideomodalopen] = useState(false);


  const handleClickOpen = (name) => {
    setLibraryDetails({ ...libraryDetails, name, open: true });
  };

  const handlevideoClickOpen = async (videoPath) => {
    try {
      const response = await ApiService('libraries', 'GET', { videoPath });
      if (response.result.video_url) {
        setVideourl(response.result.video_url);
        setVideomodalopen(true);
      } else {
        throw new Error("Failed to fetch video URL");
      }
    } catch (error) {
      console.error("Error fetching video:", error);
      setVideourl("");
      setVideomodalopen(false);
    }
  };
  

  const handleClose = () => {
    setLibraryDetails({ ...libraryDetails, open: false });
  };

  const [librariesInfo, setLibrariesInfo] = useState({ data: [], isLoading: true, error: null });

  useEffect(() => {
    setLibrariesInfo({ ...librariesInfo, isLoading: true });
    ApiService('libraries', 'GET')
      .then((response) => {
        setLibrariesInfo({ ...librariesInfo, data: response.result, isLoading: false });
      })
      .catch((error) => setLibrariesInfo({ ...librariesInfo, isLoading: false, error }));
  }, []);

  const columns = [
    { field: 'col1', headerName: 'Capability Type', width: 160 },
    { field: 'col2', headerName: 'Capability Name', flex: 0.7 },
    {
      field: 'col3',
      headerName: 'Module',
      width: 200,
      renderCell: (cellValues) => (
        <Link
          style={{ color: '#1e88e5', textDecoration: 'none' }}
          onClick={() => handleClickOpen(cellValues?.row?.col3)}
        >
          {cellValues?.row?.col3}
        </Link>
      ),
    },
    {
      field: 'col4',
      headerName: 'Training',
      width: 80,
      renderCell: (cellValues) => 
        cellValues?.row?.col4 ? (
          <SmartDisplayIcon
            onClick={() => handlevideoClickOpen(cellValues?.row?.col4)}
            sx={{
              paddingLeft: "1rem",
              fontSize: "1.7rem",
              color: "#ff5757",
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.2)",
                color: "#ba4646",
              },
            }}
          />
        ) : null,
    },
    { field: 'col5', headerName: 'Description', flex: 1 },
    { field: 'col6', headerName: 'Repo', flex: 1 },
  ];

  const rows = useMemo(() => {
    if (!librariesInfo.data || librariesInfo.data.length === 0) return [];

    const searchValue = searchTerm.toLowerCase();

    return librariesInfo.data
      .filter(library =>
        library.capabilityName.toLowerCase().includes(searchValue) ||
        library.category.toLowerCase().includes(searchValue) ||
        library.packageName.toLowerCase().includes(searchValue)
      )
      .map((library, i) => ({
        id: i + 1,
        col1: library.category,
        col2: library.capabilityName,
        col3: library.packageName,
        col4: library.videoPath ? library.videoPath : null,
        col5: library.description,
        col6: library.repo,
      }));
  }, [librariesInfo, searchTerm]);

  return (
    <div>
      <Grid style={{ marginBottom: "2.2rem" }} container spacing={2}>
        {libraryDetails.open && <Readme libraryDetails={libraryDetails} handleClose={handleClose} />}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
            <PageHeading color="black" variant="h3" textAlign="left" name="Libraries" />
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: "20rem", marginLeft: '20px' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <StyledDataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
                columns: {
                  columnVisibilityModel: {
                    col6: false, // Hide Repo Column initially
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              autoHeight
              disableRowSelectionOnClick
              loading={librariesInfo.isLoading}
              components={{
                LoadingOverlay: () => (
                  <div style={{ padding: '20px', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ),
                Toolbar: GridToolbar,
              }}
              localeText={{
                noRowsLabel: 'No Libraries found',
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Modal open={videomodalopen} onClose={() => setVideomodalopen(false)}>
        <div style={{ width: "80%", margin: "auto", marginTop: "3%", backgroundColor: "#fff", padding: "20px", borderRadius: "10px" }}>
          {videourl ? (
            <video
              src={videourl}
              controls
              style={{ width: "100%", borderRadius: "10px" }}
            />
          ) : (
            <p>Loading video...</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Libraries;
