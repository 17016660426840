export const getHeaders = () => {
    const token = localStorage.getItem('msal.idtoken');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("x-api-key", process.env.REACT_APP_X_API_KEY);
    return myHeaders
}

export const ApiService = (endPoint, method = 'GET', payload = '', resType=null ) => {
    console.log(resType, "Res type..........")

    let apiUrl = `${process.env.REACT_APP_API_URL}/${endPoint}`;
    if (method === 'GET' && payload) {
        const queryParams = new URLSearchParams(payload).toString();
        apiUrl = `${apiUrl}?${queryParams}`;
    }

    let requestOptions = {
        method,
        headers: getHeaders(),
    };

    if (payload && method !== 'GET') {
        requestOptions = { ...requestOptions, body: JSON.stringify(payload) };
    }

    if (resType !== null) {
        return fetch(apiUrl, requestOptions).then((res) => res.text());
    } else {
        return fetch(apiUrl, requestOptions).then((res) => res.json());
    }
}