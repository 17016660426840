import { BrowserRouter, Route, Routes, ScrollRestoration } from "react-router-dom"
import { Layout } from "../Components/Layout"
import Dashboard from "../pages/Dashboard"
import { Libraries } from "../pages/Libraries"
import { UsageStats } from "../pages/UsageStats"

export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/usage-stats" element={<UsageStats />} />
                    </Route>
                    <Route path="/libraries" element={<Libraries />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}